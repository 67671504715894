import {isObject, uniq, map, result, find, get} from "lodash";

import summary from "../../summary.json";
import * as TYPE from '../constants';

const defaultState = {
  questions: [],
  categories: [],
  difficulties: [],
  languages: [
    {
      value: 1,
      mode: 'javascript',
      label: 'JavaScript',
    },
    {
      value: 2,
      mode: 'text/x-java',
      label: 'Java',
    },
    {
      value: 3,
      mode: 'python',
      label: 'Python',
    },
    {
      value: 7,
      mode: 'go',
      label: 'GO',
    },
  ],
  activeLang: {
    value: 1,
    mode: 'javascript',
    label: 'JavaScript',
  },
  userCodes: undefined,
  quickTest: [],
  formErrors: undefined,
  question: undefined,
  code: undefined,
  loading: true,
  codeTab: 0,
  testTab: 0,
  solutionTab: 0,
  detailTab: 0,
  config: undefined,
  result: undefined,
  errorCode: undefined,
  submitSuccess: undefined,
  submitError: undefined,
  purchaseSuccess: false,
  runCode: false
};

const common = (state = defaultState, {type, data}) => {
  let quickTest = [];

  switch (type) {
    case TYPE.TOGGLE_PURCHASE_SUCCESS:
      return {
        ...state,
        purchaseSuccess: data,
      };
    case TYPE.TOGGLE_RUN_CODE:
      return {
        ...state,
        runCode: data,
      };
    case TYPE.QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        errorCode: data
      };
    case TYPE.QUESTION_SUBMIT_ERROR:
      return {
        ...state,
        runCode: false,
        submitError: data,
        submitSuccess: undefined,
        result: undefined,
      };
    case TYPE.QUESTION_SUBMIT_SUCCESS:
      return {
        ...state,
        runCode: false,
        submitSuccess: data,
        submitError: undefined,
        result: undefined,
      };
    case TYPE.CHANGE_LANGUAGE:
      return {
        ...state,
        submitSuccess: undefined,
        submitError: undefined,
        result: undefined,
        activeLang: data
      };
    case TYPE.QUESTION_FETCH:
    case TYPE.QUESTIONS_FETCH:
      return {
        ...state,
        ...data,
        loading: true,
      };
    case TYPE.USER_CODES_FETCH:
      return {
        ...state,
        ...data,
      };
    case TYPE.ERRORS_FORM:
      return {
        ...state,
        formErrors: data
      };
    case TYPE.QUESTIONS_LIST:
      data = process.env.NODE_ENV === 'production' ? data : summary;
      return {
        ...state,
        questions: data || [],
        categories: uniq(map(data, i => i.category)),
        difficulties: uniq(map(data, i => i.difficulty)),
      };
    case TYPE.QUESTION_QUICK_TEST:
      if (isObject(data)) {
        const keys = Object.keys(data);
        keys.map(k => quickTest.push({
          name: k,
          value: data?.[k]
        }));
      }
      return {
        ...state,
        quickTest
      };
    case TYPE.QUESTION_ITEM:
      const javascript = result(find(data?.code, o => o.language === 1), 'source.starting', '');
      const java = result(find(data?.code, o => o.language === 2), 'source.starting', '');
      const python = result(find(data?.code, o => o.language === 3), 'source.starting', '');
      const go = result(find(data?.code, o => o.language === 7), 'source.starting', '');
      const test = data?.test?.[0];

      if (isObject(test)) {
        const keys = Object.keys(test);
        keys.map(k => quickTest.push({
          name: k,
          value: test?.[k]
        }));
      }

      return {
        ...state,
        question: data,
        quickTest,
        testTab: 0,
        codeTab: 0,
        detailTab: 0,
        solutionTab: 0,
        code: {
          javascript: [
            javascript,
            javascript,
            javascript
          ],
          java: [
            java,
            java,
            java
          ],
          python: [
            python,
            python,
            python
          ],
          go: [
            go,
            go,
            go
          ]
        },
        userCodes: undefined,
        result: undefined,
        submitSuccess: undefined,
        submitError: undefined,
        loading: false,
      };
    case TYPE.USER_CODES_SAVE:
      return {
        ...state,
        userCodes: data,
        code: {
          javascript: [
            get(data, 'solutions.javascript[0]') ?? get(state.code, 'javascript[0]'),
            get(data, 'solutions.javascript[1]') ?? get(state.code, 'javascript[1]'),
            get(data, 'solutions.javascript[2]') ?? get(state.code, 'javascript[2]')
          ],
          java: [
            get(data, 'solutions.java[0]') ?? get(state.code, 'java[0]'),
            get(data, 'solutions.java[1]') ?? get(state.code, 'java[1]'),
            get(data, 'solutions.java[2]') ?? get(state.code, 'java[2]')
          ],
          python: [
            get(data, 'solutions.python[0]') ?? get(state.code, 'python[0]'),
            get(data, 'solutions.python[1]') ?? get(state.code, 'python[1]'),
            get(data, 'solutions.python[2]') ?? get(state.code, 'python[2]')
          ],
          go: [
            get(data, 'solutions.go[0]') ?? get(state.code, 'go[0]'),
            get(data, 'solutions.go[1]') ?? get(state.code, 'go[1]'),
            get(data, 'solutions.go[2]') ?? get(state.code, 'go[2]')
          ]
        },
      };
    case TYPE.SET_DETAIL_TAB:
      return {
        ...state,
        detailTab: data
      };
    case TYPE.SET_SOLUTION_TAB:
      return {
        ...state,
        solutionTab: data
      };
    case TYPE.SET_TEST_TAB:
      return {
        ...state,
        testTab: data
      };
    case TYPE.SET_CODE_TAB:
      return {
        ...state,
        codeTab: data
      };
    case TYPE.SET_CODE:
      return {
        ...state,
        code: data
      };
    case TYPE.SET_RESULT:
      return {
        ...state,
        runCode: false,
        result: data,
        submitSuccess: undefined,
        submitError: undefined,
      };
    default:
      return state;
  }
};

export default common
