import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';

import ReactDOM from 'react-dom';
import React from 'react';

import * as serviceWorker from './serviceWorker';
import App from "./App";

ReactDOM.render(<App/>, document.getElementById('container'));

serviceWorker.unregister();
