import {
  LOGOUT,
  IDENTITY_SAVE,
  IDENTITY_FETCH,
  TOGGLE_AUTH_POPPER,
} from '../constants';

export const logout = () => ({
  type: LOGOUT,
});
export const fetchIdentity = data => ({
  type: IDENTITY_FETCH,
  data,
});
export const saveIdentity = data => ({
  type: IDENTITY_SAVE,
  data,
});
export const toggleAuthPopper = bool => ({
  type: TOGGLE_AUTH_POPPER,
  data: bool
})
