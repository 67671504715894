import {call, put} from "redux-saga/effects";

import {requestQuestion, requestQuestions, requestQuestionUserCode} from "../api";
import {questionError, questionsList, saveQuestion, saveUserCodes} from "../action/common";

export function* questionFetch({name}) {
  try {
    const {question} = yield call(requestQuestion, name);
    yield put(saveQuestion(question));
    yield put(questionError(undefined));
  } catch (error) {
    if (error.response && (error.response.status === 404 || error.response.status === 403)) {
      yield put(questionError(error.response.status));
    } else {
      console.warn(error);
    }
  }
}

export function* userCodesFetch({name}) {
  try {
    const {sandbox, solutions} = yield call(requestQuestionUserCode, name);
    yield put(saveUserCodes({sandbox, solutions}));
  } catch (error) {
    console.warn(error);
  }
}

export function* questionsFetch() {
  try {
    const {questions} = yield call(requestQuestions);
    yield put(questionsList(questions));
  } catch (error) {
    console.warn(error);
  }
}
