export const ERRORS_FORM = 'ERRORS_FORM';

export const QUESTIONS_LIST = 'QUESTIONS_LIST';
export const QUESTION_ITEM = 'QUESTION_ITEM';
export const QUESTION_ERROR = 'QUESTION_ERROR';
export const QUESTION_SUBMIT_ERROR = 'QUESTION_SUBMIT_ERROR';
export const QUESTION_SUBMIT_SUCCESS = 'QUESTION_SUBMIT_SUCCESS';
export const QUESTION_QUICK_TEST = 'QUESTION_QUICK_TEST';
export const QUESTIONS_FETCH = 'QUESTIONS_FETCH';
export const QUESTION_FETCH = 'QUESTION_FETCH';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const TOGGLE_RUN_CODE = 'TOGGLE_RUN_CODE';
export const TOGGLE_PURCHASE_SUCCESS = 'TOGGLE_PURCHASE_SUCCESS';
export const TOGGLE_AUTH_POPPER = 'TOGGLE_AUTH_POPPER';
export const SET_CODE_TAB = 'SET_CODE_TAB';
export const SET_DETAIL_TAB = 'SET_DETAIL_TAB';
export const SET_SOLUTION_TAB = 'SET_SOLUTION_TAB';
export const SET_CODE = 'SET_CODE';
export const SET_TEST_TAB = 'SET_TEST_TAB';
export const SET_RESULT = 'SET_RESULT';
export const USER_CODES_FETCH = 'USER_CODES_FETCH';
export const USER_CODES_SAVE = 'USER_CODES_SAVE';

export const LOGOUT = 'LOGOUT';
export const IDENTITY_SAVE = 'IDENTITY_SAVE';
export const IDENTITY_FETCH = 'IDENTITY_FETCH';
