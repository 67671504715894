import {Route, Router, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import React from "react";

import './styles/sass/common.sass';

import {createStoreWithMiddleware} from "./stores/createStore";
import Loading from "./components/Loading";

import history from './utils/history';
import links from './links';

const Privacy = React.lazy(() => import('./pages/Privacy'));
const Purchase = React.lazy(() => import('./pages/Purchase'));
const Question = React.lazy(() => import('./pages/Questions/Question'));
const Questions = React.lazy(() => import('./pages/Questions'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const Legal = React.lazy(() => import('./pages/Legal'));
const Home = React.lazy(() => import('./pages/Home'));
const FAQ = React.lazy(() => import('./pages/FAQ'));

const App = () => {
  return (
    <Provider store={createStoreWithMiddleware}>
      <Router history={history}>
        <React.Suspense fallback={<Loading/>}>
          <Switch>
            <Route exact path={links.question(':name')} component={Question}/>
            <Route exact path={links.questions} component={Questions}/>
            <Route exact path={links.aboutUs} component={AboutUs}/>
            <Route exact path={links.legal} component={Legal}/>
            <Route exact path={links.purchase} component={Purchase}/>
            <Route exact path={links.privacy} component={Privacy}/>
            <Route exact path={links.home} component={Home}/>
            <Route exact path={links.faq} component={FAQ}/>
            <Route path={window.location.pathname} render={() => history.push(links.questions)}/>
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
};

export default App;
