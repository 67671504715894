import {IDENTITY_SAVE, LOGOUT, TOGGLE_AUTH_POPPER} from '../constants';

const defaultState = {
  identity: undefined,
  authPopper: false
};

const auth = (state = defaultState, {type, data}) => {
  switch (type) {
    case IDENTITY_SAVE:
      return {
        ...state,
        identity: data
      };
    case LOGOUT:
      return {
        ...state,
        identity: undefined
      };
    case TOGGLE_AUTH_POPPER:
      return {
        ...state,
        authPopper: data
      };
    default:
      return state;
  }
}

export default auth;
