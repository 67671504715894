import * as TYPE from '../constants';

export const errorsForm = errors => ({
  type: TYPE.ERRORS_FORM,
  data: errors
});

export const questionsList = items => ({
  type: TYPE.QUESTIONS_LIST,
  data: items
});

export const questionsSaga = () => ({
  type: TYPE.QUESTIONS_FETCH
});

export const questionSaga = name => ({
  type: TYPE.QUESTION_FETCH,
  name
});

export const userCodesSaga = name => ({
  type: TYPE.USER_CODES_FETCH,
  name
});

export const saveUserCodes = data => ({
  type: TYPE.USER_CODES_SAVE,
  data
});
export const saveQuestion = item => ({
  type: TYPE.QUESTION_ITEM,
  data: item
});

export const questionError = code => ({
  type: TYPE.QUESTION_ERROR,
  data: code
});

export const changeLanguage = language => ({
  type: TYPE.CHANGE_LANGUAGE,
  data: language
});

export const setCodeTab = tab => ({
  type: TYPE.SET_CODE_TAB,
  data: tab
});

export const questionQuickTest = test => ({
  type: TYPE.QUESTION_QUICK_TEST,
  data: test
});

export const setTestTab = tab => ({
  type: TYPE.SET_TEST_TAB,
  data: tab
});

export const setDetailTab = tab => ({
  type: TYPE.SET_DETAIL_TAB,
  data: tab
});

export const setSolutionTab = tab => ({
  type: TYPE.SET_SOLUTION_TAB,
  data: tab
});

export const setCode = code => ({
  type: TYPE.SET_CODE,
  data: code
});

export const setResult = data => ({
  type: TYPE.SET_RESULT,
  data
});

export const setSuccessResult = data => ({
  type: TYPE.QUESTION_SUBMIT_SUCCESS,
  data
});

export const setErrorResult = data => ({
  type: TYPE.QUESTION_SUBMIT_ERROR,
  data
});

export const toggleRunCode = bool => ({
  type: TYPE.TOGGLE_RUN_CODE,
  data: bool
});
export const togglePurchaseSuccess = bool => ({
  type: TYPE.TOGGLE_PURCHASE_SUCCESS,
  data: bool
});
