const links = {
  home: "/",
  legal: "/legal",
  aboutUs: "/about-us",
  privacy: "/privacy",
  faq: "/faq",
  purchase: "/purchase",
  questions: "/questions",
  question: name => "/questions/" + name,
};

export default links;
