import React from "react";

const Loading = () => {
  return (
    <div className="preloader loading">
      <div className="circle"/>
    </div>
  )
};
export default Loading;
