import {call, put} from 'redux-saga/effects';

import {requestLogout, requestIdentity} from '../api';
import {saveIdentity} from "../action/auth";
import history from "../../utils/history";
import links from "../../links";

export function* logout() {
  try {
    yield call(requestLogout);
    yield localStorage.removeItem('auth');
    yield call(history.push, links.home);
  } catch (error) {
    console.warn(error);
  }
}

export function* identity() {
  try {
    const user = yield call(requestIdentity);
    yield put(saveIdentity(user));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(saveIdentity(undefined));
    }
    console.warn(error);
  }
}
