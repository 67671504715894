import http from '../utils/httpService';

export const API_URL = process.env.REACT_APP_API || 'https://api.codequorum.org';

export const requestNetwork = data => http.post(`${API_URL}/auth/network`, data);
export const requestIdentity = () => http.get(`${API_URL}/auth/identity`);
export const requestLogout = () => http.post(`${API_URL}/auth/logout`);

export const requestUserCode = (question, data) => http.post(`${API_URL}/cabinet/code/${question}`, data);
export const requestPurchaseApprove = data => http.post(`${API_URL}/cabinet/purchase/approve`, data);
export const requestPurchase = data => http.post(`${API_URL}/cabinet/purchase`, data);
export const requestPromoCode = data => http.post(`${API_URL}/cabinet/promo-code/`, data);
export const requestQuestionUserCode = question => http.get(`${API_URL}/cabinet/code/${question}`);

export const requestQuestions = () => http.get(`${API_URL}/problems`);
export const requestQuestion = (name) => http.get(`${API_URL}/problems/${name}`);
export const requestQuestionSubmit = (data, config) => http.post(
  `${API_URL}/problems/run-code`,
  data,
  {timeout: 15000, ...config}
);

export const requestContactUs = data => http.post(`${API_URL}/contact-us`, data);
export const requestBugReport = data => http.post(`${API_URL}/bug-report`, data);
