import {takeLatest} from 'redux-saga/effects';

import * as ActionTypes from '../constants';
import {logout, identity} from "./auth";
import {questionFetch, questionsFetch, userCodesFetch} from "./common";

export default function* rootSaga() {
  yield takeLatest(ActionTypes.LOGOUT, logout);
  yield takeLatest(ActionTypes.IDENTITY_FETCH, identity);
  yield takeLatest(ActionTypes.USER_CODES_FETCH, userCodesFetch);
  yield takeLatest(ActionTypes.QUESTION_FETCH, questionFetch);
  yield takeLatest(ActionTypes.QUESTIONS_FETCH, questionsFetch);
}
