import axios from 'axios';

axios.defaults.timeout = 5000;

axios
  .interceptors
  .response
  .use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

    if (!expectedError) {
      const message = error.message && error.message === 'Network Error'
        ? error.message
        : 'An unexpected error occurred!';
      console.error(message)
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 401:
        localStorage.removeItem('auth');
        break;
      default:
        break;
    }
    return Promise.reject(error);
  });

axios
  .interceptors
  .request
  .use(config => {
    const token = localStorage.getItem('auth');
    if (token) config.headers = {Authorization: `${token}`};
    return config;
  });

const httpService = {
  get: (url, config) => axios.get(url, config).then(({data}) => data),
  post: (url, data, config) => axios.post(url, data, config).then(({data}) => data),
  put: (url, data, config) => axios.put(url, data, config).then(({data}) => data),
  delete: (url, config) => axios.delete(url, config).then(({data}) => data),
};

export default httpService;
